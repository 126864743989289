<template>
    <div class="-item">
        <div class="-overview">
            <img :src="addOn.thumbnailImageUrl" width="230" class="mb-2" :title="addOn.name" :alt="addOn.name" />
        </div>
        <div class="-description">
            <a v-if="!isEmbeddedMode" :href="addOn.url" class="-name" v-html="addOn.name"></a>
            <span v-else class="-name" v-html="addOn.name"></span>
            <div class="mt-1" v-html="addOn.description"></div>
        </div>
        <div class="-details text--small">
            <div v-if="addOn.companyName">Offered by: <b v-html="addOn.companyName"></b></div>
            <div v-if="addOn.location">Location: <b v-html="addOn.location"></b></div>
            <div v-if="addOn.eventLength">Length: <b v-html="addOn.eventLength"></b></div>
            <div v-if="addOn.meals">Meals: <b v-html="addOn.meals"></b></div>
            <div v-if="addOn.ageAppropriateness">Recommended&nbsp;Ages: <b v-html="addOn.ageAppropriateness"></b></div>
            <div v-if="addOn.activityLevel">Activity&nbsp;Level: <b v-html="addOn.activityLevel"></b></div>
            <div v-if="addOn.experiences">Experience(s): <b v-html="addOn.experiences"></b></div>
            <div v-if="addOn.virtuosoHotel">Virtuoso Hotel: <b v-html="addOn.virtuosoHotel"></b></div>
        </div>
    </div>
</template>


<script lang="ts">
    import { TourEvent } from "interfaces/tour";
    import { isEmbeddedMode } from "services/layout/environment";
    import { defineComponent, PropType } from "vue";

    export default defineComponent({
        props: {
            addOn: {
                type: Object as PropType<TourEvent>,
                default: undefined
            }
        },

        data() {
            return {
                isEmbeddedMode: isEmbeddedMode()
            };
        }
    });
</script>
