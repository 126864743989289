import "jquery";

const desktopRootMargin = "-214px"; // -152 header height - 60 .-info height
const desktopRootMarginNearStickySubhead = "-294px 0px 0px 0px";
const mobileTopRootMargin = "-122px"; // -56 header height - 66 .-info height

/**
 *
 * @param {JQuery} $advisorHeader - selector for '.advisor-header'
 * @param {JQuery} $stickyHeader - selector for '.sticky-advisor-header'
 * */
function advisorHeaderNameObserver($advisorHeader: JQuery, $stickyHeader: JQuery): void {
    const config = {
        rootMargin: `${(window.innerWidth >= 1200 ? desktopRootMargin : mobileTopRootMargin)} 0px 0px 0px`, // include nav bar height desktop & mobile
        threshold: 0
    };

    const stickyObserver = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry: IntersectionObserverEntry) => {
            if (!entry.isIntersecting) { // not visible in viewport
                $advisorHeader.addClass("-sticky");
                $stickyHeader.addClass("-sticky");
            } else {
                $stickyHeader.removeClass("-sticky");
                $advisorHeader.removeClass("-sticky");
            }
        });
    }, config);

    stickyObserver.observe($advisorHeader[0]);
}



function setActiveTab($tab: JQuery): void {
    if (!$tab.hasClass("-active")) {
        $(".tab-nav-container li").removeClass("-active");
        $tab.addClass("-active");
    }
}



/**
 * Sets up observers with different thresholds for each section
 */
function observeSections(): void {
    const $aboutTab = $("#tab-about");
    const $placesTab = $("#tab-places");
    const $reviewsTab = $("#tab-reviews");
    const hasPlaces = ($placesTab.length !== 0);
    const hasReviews = ($reviewsTab.length !== 0);

    // About section
    const aboutObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                if (entry.intersectionRatio > 0.5) {
                    setActiveTab($aboutTab);
                } else { // This catches scrolling up when the threshold wasn't offscreen
                    if (hasPlaces && $placesTab.hasClass("-active")) {
                        setActiveTab($aboutTab);
                    }
                }
            }
        });
    }, { threshold: [0.5, 0.9], rootMargin: desktopRootMarginNearStickySubhead });
    aboutObserver.observe(document.getElementById("about"));

    // Places Visited section
    if (hasPlaces) {
        const placesObserver = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setActiveTab($placesTab);
                }
            });
        }, { threshold: 0.5, rootMargin: desktopRootMarginNearStickySubhead });
        placesObserver.observe(document.getElementById("places"));
    }

    // Reviews & Recommendations section
    if (hasReviews) {
        const reviewsObserver = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    if (entry.intersectionRatio > 0.25) {
                        setActiveTab($reviewsTab);
                    } else { // This catches scrolling down when the threshold wasn't off screen
                        if (hasPlaces && $placesTab.hasClass("-active")) {
                            setActiveTab($reviewsTab);
                        }
                    }
                }
            });
        }, { threshold: [0.25, 0.5], rootMargin: desktopRootMarginNearStickySubhead });
        reviewsObserver.observe(document.getElementById("reviews"));
    }
}


/**
 * Runs Observers for the Advisor Detail Page
 * to implement sticky subhead and subnav highlighting
 */
export function initAdvisorSubhead(): void {
    const $advisorHeader = $(".advisor-header");
    const $stickyHeader = $(".sticky-advisor-header");
    const $sections = $("section");

    if ($advisorHeader.length && $stickyHeader.length && $sections.length) {
        // Sticky subhead
        advisorHeaderNameObserver($advisorHeader, $stickyHeader);

        // Subnav highlighting
        observeSections();
    } else {
        console.warn("Page sections not found, not applying observers");
    }
}
