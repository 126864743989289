<template>
    <div v-if="canViewComponent" id="track-sailing-component" class="mt-2">
        <button v-show="!showTrackCruiseForm" class="btn btn-primary-regular btn-sm w-100" @click="toggleTrackSailingForm()">Track Sailing</button>
        <div v-show="showTrackCruiseForm" class="-wrapper">
            <h3>Track this Sailing</h3>
            <div class="mt-1 text--small">Client Name(s)</div>
            <div class="mt-half input-group">
                <input id="track-sailing-client" v-model="clientNamesValue" type="text" maxlength="50" class="form-control" :disabled="isLoading" @keyup.enter="doTrackCruise" />
            </div>
            <div v-if="validationText" class="text-danger text--small mt-1" v-html="validationText"></div>
            <div class="mt-1">
                <button :class="['btn btn-primary-emphasis btn-sm', isLoading ? 'disabled' : '']" :disabled="isLoading" @click="doTrackCruise">Save</button>
                <button :class="['btn btn-tertiary btn-sm ms-2', isLoading ? 'disabled' : '']" :disabled="isLoading" @click="toggleTrackSailingForm(true)">Cancel</button>
            </div>
        </div>
        <div class="text--small mt-2 mx-auto d-none d-lg-block"><a :href="cruiseAlertsLink">Learn more about Virtuoso Cruise Alerts</a></div>
    </div>
</template>


<script lang="ts">
    import { AddCruiseAlertResponse } from "interfaces/responses/product-detail-responses";
    import { saveTrackSailing } from "services/api/user";
    import { isAdvisor, isVStaff } from "services/auth/user-info";
    import { toastError, toastSuccess } from "services/helpers/toasts";
    import { isEmbeddedMode } from "services/layout/environment";
    import * as virtuosoSharedHeader from "virtuoso-shared-web-ui";
    import { defineComponent } from "vue";


    export default defineComponent({
        props: {
            sailingId: {
                type: Number,
                default: undefined
            }
        },

        data() {
            return {
                canViewComponent: (isAdvisor() || isVStaff()) && !isEmbeddedMode(),
                clientNamesValue: "",
                cruiseAlertsLink: virtuosoSharedHeader.cobrandLink("/cruisealerts"),
                validationText: "",
                isLoading: false,
                showTrackCruiseForm: false
            };
        },

        methods: {
            doTrackCruise(): void {
                if (!this.clientNamesValue || this.clientNamesValue.length < 3 || this.clientNamesValue.length > 50) {
                    this.validationText = "Client name(s) must be between 3 and 50 characters.";
                    return;
                }

                this.isLoading = true;
                this.validationText = "";

                saveTrackSailing(this.sailingId, this.clientNamesValue).then((resultJSON: AddCruiseAlertResponse) => {
                    if (resultJSON && resultJSON.success) {
                        toastSuccess("Sailing tracked successfully!");
                        this.toggleTrackSailingForm();
                        this.clientNamesValue = "";
                        this.isLoading = false;
                    } else {
                        toastError("Error tracking this sailing, please try again.");
                        this.isLoading = false;
                    }
                }, () => {

                    toastError("Error tracking this sailing, please try again.");
                    this.isLoading = false;
                });
            },

            toggleTrackSailingForm(clearValue = false): void {
                this.validationText = "";
                if (clearValue) {
                    this.clientNamesValue = "";
                }

                this.showTrackCruiseForm = !this.showTrackCruiseForm;
            }
        }
    });
</script>
