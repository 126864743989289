<template>
    <div class="slab product-features mt-6">
        <div class="container">
            <h2 class="text--serif">At the Hotel</h2>
            <template v-for="(featureCategory, index) in features">
                <div v-if="featureCategory.features.length" :key="index" class="-category">
                    <h4 v-html="featureCategory.title"></h4>
                    <ul>
                        <li v-for="(feature, featureIndex) in featureCategory.features" :key="featureIndex"><i :class="feature.icon"></i> {{ feature.label }}</li>
                    </ul>
                </div>
            </template>
        </div>
    </div>
</template>


<script lang="ts">
    import { HotelFeatures } from "interfaces/hotel";
    import { ProductFeatureItem, ProductFeatureItemMap } from "interfaces/product";
    import { defineComponent, PropType } from "vue";

    const hotelFeatureIconMap: ProductFeatureItemMap[] = [
        { icon: "icon-lock", id: "Features-24-hour Security", label: "24-hour Security" },
        { icon: "icon-adult", id: "Features-Adults Only", label: "Adults Only" },
        { icon: "icon-air-conditioner", id: "Features-Air Conditioning", label: "Air Conditioning" },
        { icon: "icon-all-inclusive", id: "Features-All-inclusive", label: "All-inclusive" },
        { icon: "icon-banquet", id: "Features-Banquet Facilities", label: "Banquet Facilities" },
        { icon: "icon-printer", id: "Features-Business Center", label: "Business Center" },
        { icon: "icon-coins", id: "Features-Casino", label: "Casino" },
        { icon: "icon-children", id: "Features-Children's Programs", label: "Children's Programs" },
        { icon: "icon-disco", id: "Features-Club Floor", label: "Club Floor" },
        { icon: "icon-parking", id: "Features-Complimentary Parking", label: "Complimentary Parking" },
        { icon: "icon-networking", id: "Features-Conference Facilities", label: "Conference Facilities" },
        { icon: "icon-connected-rooms", id: "Features-Connecting Rooms", label: "Connecting Rooms" },
        { icon: "icon-culinary", id: "Features-Culinary Program", label: "Culinary Program" },
        { icon: "icon-connection", id: "Features-Internet Access", label: "Internet Access" },
        { icon: "icon-library", id: "Features-Library", label: "Library" },
        { icon: "icon-lounge", id: "Features-Lounge/Bar", label: "Lounge/Bar" },
        { icon: "icon-meal-plans", id: "Features-Meal Plans Available", label: "Meal Plans Available" },
        { icon: "icon-michelin", id: "Features-Michelin Star Restaurant", label: "Michelin Star Restaurant" },
        { icon: "icon-multi-lingual", id: "Features-Multi-lingual Staff", label: "Multi-lingual Staff" },
        { icon: "icon-disco", id: "Features-Nightclub", label: "Nightclub" },
        { icon: "icon-parking", id: "Features-Parking", label: "Parking" },
        { icon: "icon-paw", id: "Features-Pet Friendly", label: "Pet Friendly" },
        { icon: "icon-restaurant", id: "Features-Restaurant", label: "Restaurant" },
        { icon: "icon-shopping", id: "Features-Shopping", label: "Shopping" },
        { icon: "icon-villa", id: "Features-Villas", label: "Villas" },
        { icon: "icon-wheelchair", id: "Features-Wheelchair Accessible", label: "Wheelchair Accessible" },
        { icon: "icon-bathrobe", id: "In Your Room-Bathrobes", label: "Bathrobes" },
        { icon: "icon-butler", id: "In Your Room-Butler Service", label: "Butler Service" },
        { icon: "icon-tea", id: "In Your Room-Complimentary Coffee/Tea", label: "Complimentary Coffee/Tea" },
        { icon: "icon-newspaper", id: "In Your Room-Complimentary Newspaper", label: "Complimentary Newspaper" },
        { icon: "icon-hair-dryer", id: "In Your Room-Hair Dryer", label: "Hair Dryer" },
        { icon: "icon-iron", id: "In Your Room-Iron/Ironing Board", label: "Iron/Ironing Board" },
        { icon: "icon-mini-bar", id: "In Your Room-Mini Bar", label: "Mini Bar" },
        { icon: "icon-safe", id: "In Your Room-Safe", label: "Safe" },
        { icon: "icon-slippers", id: "In Your Room-Slippers", label: "Slippers" },
        { icon: "icon-bicycle", id: "Recreation-Bicycle Rental", label: "Bicycle Rental" },
        { icon: "icon-fitness", id: "Recreation-Fitness Center", label: "Fitness Center" },
        { icon: "icon-golf", id: "Recreation-Golf", label: "Golf" },
        { icon: "icon-horseback-riding", id: "Recreation-Horseback Riding", label: "Horseback Riding" },
        { icon: "icon-jacuzzi1", id: "Recreation-Jacuzzi/Whirlpool", label: "Jacuzzi/Whirlpool" },
        { icon: "icon-pool", id: "Recreation-Pool", label: "Pool" },
        { icon: "icon-sauna", id: "Recreation-Sauna", label: "Sauna" },
        { icon: "icon-skiing", id: "Recreation-Skiing", label: "Skiing" },
        { icon: "icon-snow-sports", id: "Recreation-Snow Sports", label: "Snow Sports" },
        { icon: "icon-spa", id: "Recreation-Spa", label: "Spa" },
        { icon: "icon-tennis", id: "Recreation-Tennis Courts", label: "Tennis Courts" },
        { icon: "icon-water-sports", id: "Recreation-Water Sports", label: "Water Sports" },
        { icon: "icon-babysitting", id: "Services-Babysitting Services", label: "Babysitting Services" },
        { icon: "icon-car", id: "Services-Car Rental Desk", label: "Car Rental Desk" },
        { icon: "icon-shuttle", id: "Services-Complimentary Airport Transfers", label: "Complimentary Airport Transfers" },
        { icon: "icon-concierge", id: "Services-Concierge Desk", label: "Concierge Desk" },
        { icon: "icon-exchange", id: "Services-Currency Exchange", label: "Currency Exchange" },
        { icon: "icon-hearing-impaired", id: "Services-Hearing Impaired Services", label: "Hearing Impaired Services" },
        { icon: "icon-house-safe", id: "Services-House Safe", label: "House Safe" },
        { icon: "icon-housekeeping", id: "Services-Housekeeping -- Twice Daily", label: "Housekeeping -- Twice Daily" },
        { icon: "icon-laundry", id: "Services-Laundry/Dry Cleaning/Pressing", label: "Laundry/Dry Cleaning/Pressing" },
        { icon: "icon-limo", id: "Services-Limousine Service", label: "Limousine Service" },
        { icon: "icon-room-service", id: "Services-Room Service", label: "Room Service" },
        { icon: "icon-room-service", id: "Services-Room Service 24-hours", label: "Room Service 24-hours" },
        { icon: "icon-salon", id: "Services-Salon", label: "Salon" },
        { icon: "icon-shoe", id: "Services-Shoeshine Service", label: "Shoeshine Service" },
        { icon: "icon-turndown", id: "Services-Turndown Service", label: "Turndown Service" },
        { icon: "icon-valet", id: "Services-Valet Parking", label: "Valet Parking" },
        { icon: "icon-wakeup-call", id: "Services-Wakeup Calls", label: "Wakeup Calls" },
        { icon: "icon-wedding", id: "Services-Wedding Services", label: "Wedding Services" }
    ];


    export default defineComponent({
        props: {
            hotelFeatures: {
                type: Object as PropType<HotelFeatures>,
                default: undefined
            }
        },

        data() {
            return {
                features: []
            };
        },

        mounted: function (): void {
            const featuresItems: ProductFeatureItem[] = [];
            if (this.hotelFeatures.features && this.hotelFeatures.features.length) {
                this.hotelFeatures.features.forEach((feature: string) => featuresItems.push(this.buildFeatureItem("Features", feature)));
            }
            this.features.push({
                features: featuresItems,
                title: "Features"
            });

            const inYourRoomItems: ProductFeatureItem[] = [];
            if (this.hotelFeatures.inYourRoom && this.hotelFeatures.inYourRoom.length) {
                this.hotelFeatures.inYourRoom.forEach((feature: string) => inYourRoomItems.push(this.buildFeatureItem("In Your Room", feature)));
            }
            this.features.push({
                features: inYourRoomItems,
                title: "In Your Room"
            });

            const recreationItems: ProductFeatureItem[] = [];
            if (this.hotelFeatures.recreation && this.hotelFeatures.recreation.length) {
                this.hotelFeatures.recreation.forEach((feature: string) => recreationItems.push(this.buildFeatureItem("Recreation", feature)));
            }
            this.features.push({
                features: recreationItems,
                title: "Recreation"
            });

            const servicesItems: ProductFeatureItem[] = [];
            if (this.hotelFeatures.services && this.hotelFeatures.services.length) {
                this.hotelFeatures.services.forEach((feature: string) => servicesItems.push(this.buildFeatureItem("Services", feature)));
            }
            this.features.push({
                features: servicesItems,
                title: "Services"
            });
        },

        methods: {
            buildFeatureItem(featureCategory: string, featureName: string): ProductFeatureItem {
                return {
                    icon: hotelFeatureIconMap.find((el: ProductFeatureItemMap) => el.id === `${featureCategory}-${featureName}`)?.icon || "icon-check-ut",
                    label: featureName
                };
            }


        }

    });
</script>
