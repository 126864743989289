<template>
    <div class="product-features -narrow mt-2">
        <ul>
            <li v-for="(experience, index) in mappedExperiences" :key="index"><i :class="experience.icon"></i> {{ experience.label }}</li>
        </ul>
    </div>
</template>


<script lang="ts">
    import { ProductFeatureItem, ProductFeatureItemMap } from "interfaces/product";
    import { defineComponent, PropType } from "vue";

    const tourExperienceIconMap: ProductFeatureItemMap[] = [
        { icon: "icon-h20-adventure", id: "Tour-Adventure", label: "Adventure" },
        { icon: "icon-Air", id: "Tour-Air & Helicopter Tours", label: "Air & Helicopter Tours" },
        { icon: "icon-Cruises", id: "Tour-Cruises & Water Tours", label: "Cruises & Water Tours" },
        { icon: "icon-h20-local-immersion", id: "Tour-Culture, History & Arts", label: "Culture, History & Arts" },
        { icon: "icon-Family", id: "Tour-Family Vacations", label: "Family Vacations" },
        { icon: "icon-festivals", id: "Tour-Festivals & Events", label: "Festivals & Events" },
        { icon: "icon-Food", id: "Tour-Food & Wine", label: "Food & Wine" },
        { icon: "icon-h20-beach", id: "Tour-Islands & Beaches", label: "Islands & Beaches" },
        { icon: "icon-lgbtq", id: "Tour-LGBTQ Travel", label: "LGBTQ Travel" },
        { icon: "icon-Rail", id: "Tour-Rail Journeys", label: "Rail Journeys" },
        { icon: "icon-Romance", id: "Tour-Romance, Weddings & Honeymoons", label: "Romance, Weddings & Honeymoons" },
        { icon: "icon-Sightseeing", id: "Tour-Sightseeing", label: "Sightseeing" },
        { icon: "icon-h20-golf", id: "Tour-Sports", label: "Sports" },
        { icon: "icon-Walking", id: "Tour-Walking & Biking", label: "Walking & Biking" },
        { icon: "icon-h20-wellness-ut", id: "Tour-Wellness", label: "Wellness" },
        { icon: "icon-wheelchair", id: "Tour-Wheelchair Accessible", label: "Wheelchair Accessible" },
        { icon: "icon-Wildlife", id: "Tour-Wildlife & Nature", label: "Wildlife & Nature" }
    ];


    export default defineComponent({
        props: {
            tourExperiences: {
                type: Array as PropType<string[]>,
                default: undefined
            }
        },

        data() {
            return {
                mappedExperiences: [] as ProductFeatureItem[]
            };
        },

        mounted: function (): void {
            if (this.tourExperiences && this.tourExperiences.length) {
                this.tourExperiences.forEach((experience: string) => this.mappedExperiences.push(this.buildFeatureItem(experience)));
            }
        },

        methods: {
            buildFeatureItem(experienceName: string): ProductFeatureItem {
                return {
                    icon: tourExperienceIconMap.find((el: ProductFeatureItemMap) => el.id === `Tour-${experienceName}`)?.icon || "icon-check-ut",
                    label: experienceName
                };
            }
        }

    });
</script>
