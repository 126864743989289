<template>
    <component :is="catalogType.slice(0, -1) + '-details-component'" :product-id="productId"></component>
</template>


<script lang="ts">
    import CruiseDetailsComponent from "components/products/details/cruise-details.vue";
    import HotelDetailsComponent from "components/products/details/hotel-details.vue";
    import TourDetailsComponent from "components/products/details/tour-details.vue";
    import AdvisorDetailsComponent from "components/advisor/advisor-details.vue";
    import { ProductType } from "interfaces/enums";
    import { defineComponent, PropType } from "vue";

    export default defineComponent({

        components: {
            AdvisorDetailsComponent,
            CruiseDetailsComponent,
            HotelDetailsComponent,
            TourDetailsComponent
        },

        props: {
            catalogType: {
                type: String as PropType<ProductType>,
                default: undefined
            },
            productId: {
                type: Number,
                default: undefined
            }
        }

    });
</script>
