<template>
    <ol class="list-unstyled itinerary-container">
        <li v-for="(day, dayIndex) in itinerary" :key="dayIndex" :class="containerClass">
            <div class="-day context-dark">
                <div>Day {{ day.itineraryDay }}</div>
                <div v-if="day.dateFormatted" class="-date" v-html="day.dateFormatted"></div>
            </div>
            <div class="-content">
                <div class="-overview">
                    <div class="-stops">
                        <div class="-label">Location(s)</div>
                        <ol class="list-unstyled">
                            <li v-for="(stop, stopIndex) in day.stops" :key="stopIndex" class="text-emphasis">
                                <i v-if="isCruiseTour" :class="['me-1', (stop.isOnLand) ? 'icon-shuttle' : 'icon-Cruises']"></i>
                                {{ stop.location }}
                                <span v-if="isCruise && (stop.timeArrive || stop.timeDepart)" class="-times">
                                    <span v-if="stop.timeArrive">Arrive: {{ stop.timeArrive }}</span>
                                    <span v-if="stop.timeDepart">Depart: {{ stop.timeDepart }}</span>
                                </span>
                            </li>
                        </ol>
                    </div>
                    <div v-if="isTour" class="-lodging">
                        <div class="-label">Hotel</div>
                        <div v-if="day.hotelName" class="text-emphasis" v-html="day.hotelName"></div>
                        <div v-else class="text-emphasis">None</div>
                    </div>
                    <div v-if="isTour" class="-meals">
                        <div class="-label">Meals</div>
                        <div v-if="day.meals" class="text-emphasis" v-html="day.meals"></div>
                        <div v-else class="text-emphasis">None</div>
                    </div>
                </div>
                <div v-if="day.description" class="-description truncate-vertical truncate-3" data-viewmoretext="More details" data-viewlesstext="Fewer details" v-html="day.description"></div>
            </div>
        </li>
    </ol>
</template>


<script lang="ts">
    import { ItineraryDay } from "interfaces/date";
    import { ProductType } from "interfaces/enums";
    import { applyVerticalTruncation } from "services/helpers/truncation";
    import { defineComponent, nextTick, PropType } from "vue";


    export default defineComponent({
        props: {
            productType: {
                type: String as PropType<ProductType>,
                default: undefined
            },
            isCruiseTour: {
                type: Boolean
            },
            itinerary: {
                type: Array as PropType<ItineraryDay[]>,
                default: undefined
            }
        },

        data() {
            return {
                containerClass: "itinerary-row",
                isCruise: false,
                isTour: false
            };
        },

        mounted: function (): void {
            this.containerClass += ` -${this.productType}`;
            if (this.productType === ProductType.CRUISES) {
                this.isCruise = true;
            } else if (this.productType === ProductType.TOURS) {
                this.isTour = true;
            }

            nextTick(() => {
                applyVerticalTruncation($(".truncate-vertical", ".itinerary-container"));
            });
        }

    });
</script>
