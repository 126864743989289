import "jquery";

/**
 * applyVerticalTrunctation - Sets truncation with a gradient and "View more" link on the bottom
 *   The whole element is clickable to expand.
 *   Height of the truncated content is based off of the max-height of .truncate-vertical.intialized,
        which defaults to the values in .truncate-5
 *   Use the @truncate-lines mixin to set different heights.
 *   Optionally add a data-data-viewmoretext element to specify link text other than "View more".
 * @param containerElement - The block element(s) to be vertically truncated, defaults to $(".truncate-vertical")
 */
export function applyVerticalTruncation($truncateElements: JQuery = $(".truncate-vertical")): void {
    $truncateElements.each(function (): void {

        const $this: JQuery = $(this);

        if (!$this.hasClass("-initialzed")) {

            const viewMoreText: string = $this.data("viewmoretext") || "View more";
            const viewLessText: string = $this.data("viewlesstext") || "View less";

            // Get container height - have to set the variable here because Safari dies when getting height
            // after a max-height is applied by a new class
            const initialHeight: number = $this.height();

            $this.addClass("-initialized");

            // Get copyHeight by cloning our element, grabbing the value, then removing it
            // This stupidity is because Safari barfs when a max-height is added via a class
            const $clone: JQuery = $this.clone().hide().appendTo("body");
            const copyHeight: number = parseFloat($clone.css("max-height"));
            $clone.remove();

            // Check to see if the text is long enough to even worry with this mess
            if (initialHeight < copyHeight) {
                $this.addClass("-not-needed");
            } else {

                // Add the inner div
                $this.wrapInner('<div class="-inner"></div>');
                const $copyInner: JQuery = $this.find(".-inner");

                // Add the 'View less" button now so it takes up space when expanding
                $copyInner.append(`<div><button class="btn-link -toggle -view-less">${viewLessText} <i class="icon-angle-up"></i></button></div>`);

                const maxHeight = $copyInner.outerHeight(true);

                // Add the gradient overlay
                $this.append(`<div class="-gradient"><button class="btn-link -toggle">${viewMoreText} <i class="icon-angle-down"></i></button></div>`);

                // Allow re-collapsing
                $(".-view-less", $copyInner).on("click", function (): void {
                    $this.css({ "max-height": maxHeight + "px" }); // Explicitly reset the current full height from 'none'
                    setTimeout(function (): void { // Let the max-height percolate so the transition transitions
                        $this.css({ "max-height": copyHeight + "px" }); // Then back down to collapsed
                    }, 1);

                    // Re-enable "View more"
                    setTimeout(function (): void { // Prevent immediate re-execution
                        applyTruncationHandlers($this, maxHeight);
                        $this.removeClass("-reveal");
                    }, 100);
                });

                applyTruncationHandlers($this, maxHeight);
            }
        }
    });

}

function applyTruncationHandlers($el: JQuery, maxHeight: number): void {
    $el.one("click", (): void => {
        $el.css({ "max-height": maxHeight + "px" });
        $el.on("transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd",
            function (e): void {
                if (e.target === e.currentTarget) {
                    // To keep multiple events from being fired (child elements with transitions)
                    $el.css({ "max-height": "none" }); // Set the max-height to none after the transition so page resizing works
                    $el.off("transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd"); // Unbind the transition event
                }
            });

        $el.addClass("-reveal");
    });
}
