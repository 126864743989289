<template>
    <div v-if="isReady" ref="product-detail" class="product-detail">
        <top-splash-component :top-splash-data="topSplashData" @promo-click="showTab('promotions', false, true)"></top-splash-component>
        <div class="container mt-4">
            <div class="product-detail-overview mb-6">
                <div>
                    <h1 v-html="tour.tourName"></h1>
                    <div class="mt-1">
                        {{ tour.tourType }}
                        <template v-if="tour.subType">
                            | {{ tour.subType }}
                        </template>
                    </div>

                    <div class="mt-2 -description">
                        <div v-if="tour.childTours.length > 1" class="mt-2">
                            Travel Dates<br />
                            <template v-if="isEmbeddedMode">
                                Ask your advisor about available travel dates
                            </template>
                            <label v-else class="select--styled">
                                <select id="itineraryNav" class="w-100" aria-label="Select Travel Dates" @change="goToTour">
                                    <option v-for="(item, index) in tour.childTours"
                                            :key="index"
                                            :value="item.id"
                                            :selected="item.id === tour.id"
                                            :data-url="item.url"
                                            v-html="item.travelDates"></option>
                                </select>
                            </label>
                        </div>
                        <template v-else>
                            <b>{{ tour.travelDates }}</b>
                            <br />
                        </template>
                        <b v-html="formattedLocation"></b><br />
                        <a v-if="tour.brandUrl && !isEmbeddedMode" class="weglot-exclude" :href="tour.brandUrl">
                            <b v-html="tour.companyName"></b>
                        </a>
                        <b v-else class="weglot-exclude" v-html="tour.companyName"></b>
                    </div>

                    <div v-if="isDayTour" class="mt-2">
                        <div v-if="tour.meals">Meals: <b v-html="tour.meals"></b></div>
                        <div v-if="tour.ageAppropriateness" class="mt-1">
                            Recommended Ages: <b v-html="tour.ageAppropriateness"></b>
                        </div>
                        <div v-if="tour.activityLevel" class="mt-1">
                            Activity Level: <b v-html="tour.activityLevel"></b>
                            <b v-if="isWheelchairAccessible">&bull; Wheelchair Accessible</b>
                        </div>
                    </div>

                    <tour-experiences-component v-if="tour.experiences.length"
                                                :tour-experiences="tour.experiences"></tour-experiences-component>

                    <div v-if="tour.tourDescription"
                         class="mt-3 truncate-vertical truncate-15"
                         data-viewmoretext="More details"
                         data-viewlesstext="Fewer details"
                         v-html="tour.tourDescription"></div>

                    <h2 class="text--serif mt-3">At a Glance</h2>

                    <MapView v-if="routeMapConfig.routeMarkers.length" type="route" :route-map-config="routeMapConfig" />

                    <div v-if="tour.whatIsIncluded" class="mt-2">
                        <div class="mb-1"><b>Included</b></div>
                        <div v-html="tour.whatIsIncluded"></div>
                    </div>

                    <div v-if="tour.whatIsNotIncluded" class="mt-2">
                        <div class="mb-1"><b>Not Included</b></div>
                        <div v-html="tour.whatIsNotIncluded"></div>
                    </div>
                </div>
                <div class="-gallery mt-5 mt-md-0 mb-6">
                    <image-gallery-component v-if="tour.galleryImages && tour.galleryImages.length"
                                             :gallery-data="tour.galleryImages"
                                             :product-id="tour.id.toString()"
                                             :product-name="tour.tourName"
                                             :product-type="catalogType"></image-gallery-component>
                    <recommended-advisors-component v-if="!suppressRecommendedAdvisors"
                                                    :advisor-query="recommendedAdvisorQuery"
                                                    :product-type="catalogType"
                                                    :set-recommended-advisor-ids="setRecommendedAdvisorIds"></recommended-advisors-component>
                    <a v-if="isNetworkUser && !isEmbeddedMode"
                       id="b2blink-legacy-link-label"
                       class="btn btn-primary-emphasis btn-sm d-block mt-3"
                       :href="legacyLink"
                       @click="doLegacyClick"
                       v-html="legacyLinkLabel"></a>
                    <button class="wl-heartable -save-this mt-3"
                            data-wl-type="tour"
                            :data-wl-id="heartableUrl"
                            :data-wl-title="tour.tourName"
                            :data-wl-list-name="wanderlistTourName"
                            aria-label="Save to Wanderlist"></button>
                </div>
            </div>
        </div>
        <div v-if="showTabBlock" id="detail-tabs" class="jump-target"></div>
        <div v-if="showTabBlock" class="container d-none d-md-block">
            <ul ref="tabNavContainer" class="tab-nav-container">
                <li v-if="tour.itinerary.length > 1" id="tab-itinerary">
                    <button @click="showTab('itinerary')">Itinerary</button>
                </li>
                <li v-if="tour.virtuosoBenefits" id="tab-benefits">
                    <button @click="showTab('benefits')">Virtuoso Benefits</button>
                </li>
                <li v-if="tour.promotions.length" id="tab-promotions">
                    <button @click="showTab('promotions')">Promotions</button>
                </li>
                <li v-if="tour.addOnDayTours.length" id="tab-add-ons">
                    <button @click="showTab('add-ons')">Add-ons</button>
                </li>
            </ul>
        </div>
        <div v-if="showTabBlock" class="slab -tab-slab">
            <div class="container">
                <ul class="tab-content">
                    <li v-if="tour.itinerary.length > 1" id="tc-itinerary">
                        <button class="tab-nav" @click="showTab('itinerary')">
                            Itinerary
                        </button>
                        <div class="-container">
                            <h4 class="tab-title">Itinerary</h4>
                            <product-itinerary-component :itinerary="tour.itinerary"
                                                         :product-type="catalogType"
                                                         :is-cruise-tour="false"></product-itinerary-component>
                        </div>
                    </li>
                    <li v-if="tour.virtuosoBenefits" id="tc-benefits">
                        <button class="tab-nav" @click="showTab('benefits')">
                            Virtuoso Benefits
                        </button>
                        <div class="-container">
                            <h4 class="tab-title">Virtuoso Benefits</h4>
                            <h2 class="text--serif">Exclusively for Virtuoso Guests</h2>
                            <div class="mt-2" v-html="tour.virtuosoBenefits"></div>
                        </div>
                    </li>
                    <li v-if="tour.promotions.length" id="tc-promotions">
                        <button class="tab-nav" @click="showTab('promotions')">
                            Promotions
                        </button>
                        <div class="-container">
                            <h4 class="-title">Promotions</h4>
                            <promotions-component :product-type="catalogType" :promotions="tour.promotions"></promotions-component>
                        </div>
                    </li>
                    <li v-if="tour.addOnDayTours.length" id="tc-add-ons">
                        <button class="tab-nav" @click="showTab('add-ons')">Add-ons</button>
                        <div class="-container">
                            <h4 class="tab-title">Add-ons</h4>
                            <div class="mb-4">
                                <h2 class="text--serif">Excursions</h2>
                                <div v-for="(segment, segmentIndex) in tour.addOnDayTours" :key="segmentIndex" class="add-ons-box mt-2">
                                    <div class="-title-row">
                                        <div class="-location">
                                            {{ segment.addOnDate }}
                                            <span class="d-none d-md-inline">&mdash;</span><span class="d-md-none"><br /></span>
                                            {{ segment.addOnLocation }}
                                        </div>
                                        <div class="-toggle">
                                            <button @click.prevent.stop="toggleAddOn('shore-excursion-' + segmentIndex)">
                                                <i :id="'add-on-toggle-shore-excursion-' + segmentIndex"
                                                   class="icon-plus-circle-ut"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div :id="'add-on-container-shore-excursion-' + segmentIndex"
                                         class="add-on-container">
                                        <add-on-tours-component v-for="(pkg, pkgIndex) in segment.addOns" :key="pkgIndex" :add-on="pkg"></add-on-tours-component>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <LogoSplash v-else />
</template>

<script lang="ts">
    import AddOnToursComponent from "components/products/details/add-on-tours.vue";
    import ProductItineraryComponent from "components/products/details/product-itinerary.vue";
    import PromotionsComponent from "components/products/details/promotions.vue";
    import TourExperiencesComponent from "components/products/details/tour-experiences.vue";
    import RecommendedAdvisorsComponent from "components/advisor/recommended-advisors.vue";
    import ImageGalleryComponent from "components/shared/image-gallery.vue";
    import LogoSplash from "components/shared/logo-splash.vue";
    import MapView from "components/shared/map-view.vue";
    import TopSplashComponent from "components/shared/top-splash.vue";
    import { tourBrandSupplierTypesRestricted } from "config/collections";
    import { ProductDetailsTourResponse } from "interfaces/responses/product-detail-responses";
    import { RecommendedAdvisorQuery } from "interfaces/advisor";
    import { ProductType, SupplierType } from "interfaces/enums";
    import { GalleryItem } from "interfaces/image";
    import { ProductDetailsTour, ProductTopSplash, Promotion } from "interfaces/product";
    import { AddOnDayTours, DayTourPackage } from "interfaces/tour";
    import { getProductDetails } from "services/api/products";
    import { isAdvisor, isNetworkUser, isSupplier, isVStaff } from "services/auth/user-info";
    import { sanitizeUserGeneratedContent } from "services/helpers/html";
    import { hydrateImageGallery } from "services/helpers/images";
    import { toastError } from "services/helpers/toasts";
    import { applyVerticalTruncation } from "services/helpers/truncation";
    import { isEmbeddedMode, isMobileScreenWidth } from "services/layout/environment";
    import { setB2BDesktopCookie } from "services/layout/metadata";
    import { trackEvent } from "services/analytics";
    import { enableHearts } from "services/wanderlist";
    import * as virtuosoSharedHeader from "virtuoso-shared-web-ui";
    import { defineComponent, nextTick } from "vue";

    const qsParams = virtuosoSharedHeader.parseURLParameters();

    export default defineComponent({

        components: {
            AddOnToursComponent,
            ImageGalleryComponent,
            ProductItineraryComponent,
            PromotionsComponent,
            RecommendedAdvisorsComponent,
            TopSplashComponent,
            TourExperiencesComponent,
            MapView,
            LogoSplash
        },

        props: {
            productId: {
                type: Number,
                default: undefined
            },
            suppressRecommendedAdvisors: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                catalogType: ProductType.TOURS,
                formattedLocation: "",
                heartableUrl: $("link[rel='canonical']").attr("href"),
                isAdvisorOrVStaff: (isAdvisor() || isVStaff()),
                isDayTour: false,
                isEmbeddedMode: isEmbeddedMode(),
                isReady: false,
                isNetworkUser: isNetworkUser(),
                isSupplier: isSupplier(),
                isWheelchairAccessible: false,
                legacyLink: virtuosoSharedHeader.cobrandLink(`/tours/${this.productId}`),
                legacyLinkLabel: (isSupplier()) ? "For Partners: Update Your Profile" : "For Advisors: Advanced Results",
                recommendedAdvisorQuery: {} as RecommendedAdvisorQuery,
                showTabBlock: false,
                topPromoName: "", // Referenced by GA
                topSplashData: {} as ProductTopSplash,
                tour: {} as ProductDetailsTour,
                wanderlistTourName: "",
                routeMapConfig: { routeMarkers: []}
            };
        },

        mounted: function (): void {
            this.loadTour();
        },

        methods: {
            doLegacyClick(): boolean {
                setB2BDesktopCookie(); // Set the "force desktop" cookie for network users

                return true;
            },

            goToTour(): void {
                const elSelect = document.getElementById("itineraryNav") as HTMLSelectElement;
                const newId = parseInt(elSelect.value, 10);
                if (newId && newId !== this.productId) {
                    window.location.href = $(elSelect.options[elSelect.selectedIndex]).attr("data-url");
                }
            },

            loadTour(): void {
                getProductDetails(this.catalogType, this.productId).then((resultTour: ProductDetailsTourResponse) => {
                    if (resultTour && resultTour.companyName) {

                        const thisTour: ProductDetailsTour = {
                            addOnDayTours: [],
                            companyId: resultTour.companyId || 0, // Only used for retrieving reviews
                            brandUrl: (tourBrandSupplierTypesRestricted.includes(resultTour.companyType as SupplierType)) ? virtuosoSharedHeader.cobrandLink(`/travel/luxury-tours/tour-operators/${resultTour.companyId}/${virtuosoSharedHeader.slugify(resultTour.companyName)}`) : "",
                            childTours: [],
                            experiences: resultTour.tourExperiences || [],
                            featuredImageUrl: "https://virtuoso-prod.dotcms.cloud/images/image-not-available-results-266x200.png", // fallback, replaced later
                            id: this.productId,
                            itinerary: [],
                            companyName: resultTour.companyName,
                            promotions: [],
                            activityLevel: resultTour.tourActivityLevels, // Day tours only?
                            ageAppropriateness: resultTour.tourAgeAppropriateness,
                            meals: resultTour.meals ? resultTour.meals.join(", ") : "", // Day tours only?
                            supplierType: resultTour.companyType as SupplierType,
                            tourCategory: resultTour.tourCategory,
                            tourDescription: (resultTour.tourDescription) ? sanitizeUserGeneratedContent(resultTour.tourDescription) : "",
                            tourLength: resultTour.tourLength,
                            tourName: `${resultTour.tourName} (${(resultTour.tourLength.trim()).replace(" ", "&nbsp;").replace("days", "Days").replace("hours", "Hours")})`,
                            tourNameWithoutLength: resultTour.tourName,
                            tourType: resultTour.tourType,
                            travelDates: resultTour.travelDates,
                            virtuosoBenefits: resultTour.virtuosoBenefitsDesc || "",
                            whatIsIncluded: "",
                            whatIsNotIncluded: ""
                        };

                        this.isDayTour = (thisTour.tourType === "Day Tour");
                        this.isWheelchairAccessible = thisTour.experiences.includes("Wheelchair Accessible");
                        this.formattedLocation = resultTour.embarkation;
                        if (resultTour.disembarkation && (!this.isDayTour || resultTour.embarkation !== resultTour.disembarkation)) {
                            this.formattedLocation += ` to ${resultTour.disembarkation}`;
                        }


                        // What's (Not) Included
                        if (resultTour.whatIsIncludedItems) {
                            if (resultTour.whatIsIncludedItems.length > 1) {
                                let theItems = "";
                                resultTour.whatIsIncludedItems.forEach((item: string) => {
                                    theItems += `<li>${item}</li>`;
                                });
                                thisTour.whatIsIncluded = `<ul>${theItems}</ul>`;
                            } else {
                                thisTour.whatIsIncluded = sanitizeUserGeneratedContent(resultTour.whatIsIncludedItems[0]);
                            }
                        }

                        if (resultTour.whatIsNotIncludedItems) {
                            if (resultTour.whatIsNotIncludedItems.length > 1) {
                                let theItems = "";
                                resultTour.whatIsNotIncludedItems.forEach((item: string) => {
                                    theItems += `<li>${item}</li>`;
                                });
                                thisTour.whatIsNotIncluded = `<ul>${theItems}</ul>`;
                            } else {
                                thisTour.whatIsNotIncluded = sanitizeUserGeneratedContent(resultTour.whatIsNotIncludedItems[0]);
                            }
                        }


                        // Images -- first image is the featured image
                        let galleryImages: GalleryItem[] = [];
                        if (resultTour.imageLibraryItems && resultTour.imageLibraryItems.length) {
                            galleryImages = hydrateImageGallery(resultTour.imageLibraryItems);
                            thisTour.featuredImageUrl = galleryImages[0].image;
                            thisTour.featuredImageCaption = galleryImages[0].description;
                        }
                        thisTour.galleryImages = galleryImages;

                        // Featured Video
                        if (resultTour.supplierVideos && resultTour.supplierVideos.length) {
                            const featuredVideo = resultTour.supplierVideos.find((video) => video.isFeaturedVideo);
                            if (featuredVideo) {
                                thisTour.featuredVideoCaption = featuredVideo.title;
                                thisTour.featuredVideoUrl = featuredVideo.webContentURL;
                            }
                        }


                        // Child tours, if populated
                        if (resultTour.childProducts && resultTour.childProducts.length && resultTour.childProducts.length > 1) {
                            resultTour.childProducts.forEach((childTour) => {
                                if (childTour.masterEntityId) { // && childTour.travelDates
                                    thisTour.childTours.push({
                                        id: childTour.masterEntityId,
                                        travelDates: `${childTour.travelStartDate} - ${childTour.travelEndDate}`, // childTour.travelDates,
                                        url: virtuosoSharedHeader.cobrandLink(`/travel/luxury-tours/${childTour.masterEntityId}/${virtuosoSharedHeader.slugify(resultTour.tourName)}`)
                                    });
                                }
                            });
                        }


                        // Itinerary
                        if (resultTour.itineraryPoints && resultTour.itineraryPoints.length) {
                            let countryFound = 0;
                            let previousDay = 0;
                            let currentDay = 0;
                            let previousDataDay = 0; // The day number from the data -- tours sometimes have insane, inaccurate day numbers (e.g. 138, 139, 1)
                            resultTour.itineraryPoints.forEach((it) => {
                                if (it.dayOfTour > (previousDay + 1) || it.dayOfTour < previousDay) { // Bad data -- "this" day is more than one day ahead or behind of the last day
                                    currentDay = (it.dayOfTour === previousDataDay) ? currentDay : currentDay + 1;
                                } else {
                                    currentDay = it.dayOfTour;
                                }

                                if (currentDay !== previousDay) {
                                    thisTour.itinerary.push({
                                        country: it.country,
                                        dateFormatted: (thisTour.tourType === "Multi-Day Tour" && thisTour.tourCategory === "NoSpecificDeparture") ? "" : it.segmentDate,
                                        description: "",
                                        hotelName: it.hotelName,
                                        itineraryDay: currentDay,
                                        meals: it.mealsFormatted || "None",
                                        stops: []
                                    });
                                    previousDay = currentDay;
                                }

                                if (thisTour.itinerary[currentDay - 1]) { // Sanity check, just in case
                                    thisTour.itinerary[currentDay - 1].stops.push({
                                        latitude: it.portLatitude || 0,
                                        location: it.portName || "N/A",
                                        longitude: it.portLongitude || 0
                                    });
                                }

                                if (it.portLatitude && it.portLongitude && it.portLatitude !== 0 && it.portLongitude !== 0) {
                                    this.routeMapConfig.routeMarkers.push({
                                        masterEntityId: 0,
                                        pinCoordinate: {
                                            latitude: it.portLatitude,
                                            longitude: it.portLongitude
                                        },
                                        pinType: 0,
                                        locationPath: "",
                                        title: it.portName
                                    });
                                }

                                if (it.stopDescription && it.stopDescription !== "") {
                                    if (thisTour.itinerary[currentDay - 1]) { // Sanity check, just in case
                                        thisTour.itinerary[currentDay - 1].description = sanitizeUserGeneratedContent(it.stopDescription);
                                    }
                                }
                                previousDataDay = it.dayOfTour;

                                // Wanderlist, Default List Name
                                if (it.country && countryFound === 0) {
                                    this.wanderlistTourName = it.country;
                                    countryFound++;
                                } else if (countryFound === 1) { // -- Multi-Day Tour
                                    if (it.country && it.country !== this.wanderlistTourName) {
                                        this.wanderlistTourName += ` and ${it.country}`;
                                        countryFound++;
                                    }
                                }
                            });
                        }

                        // Add Ons -- Day tours
                        if (resultTour.addOns && resultTour.addOns.addOnDayTours && resultTour.addOns.addOnDayTours.length) {
                            const dayTours: AddOnDayTours[] = [];
                            resultTour.addOns.addOnDayTours.forEach((segment, segmentIndex: number) => {
                                if (segment.dayTours && segment.dayTours.length) {
                                    dayTours.push({ // First add the date+location segment
                                        addOnDate: segment.addOnDate,
                                        addOnLocation: segment.addOnLocation,
                                        addOns: []
                                    });

                                    segment.dayTours.forEach((pkg: DayTourPackage) => { // Then loop through the tours for that segment and add them
                                        dayTours[segmentIndex].addOns.push({
                                            activityLevel: pkg.activityLevelList || "",
                                            ageAppropriateness: pkg.ageAppropriatenessList || "",
                                            companyName: pkg.supplierName,
                                            description: sanitizeUserGeneratedContent(pkg.dayTourDescription),
                                            eventDate: pkg.dayTourDateFormatted,
                                            eventLength: pkg.dayTourLength,
                                            experiences: (pkg.bulletedActivityTypes) ? pkg.bulletedActivityTypes : "",
                                            id: parseInt(pkg.dayTourMasterEntityID, 10),
                                            location: pkg.locationFormatted,
                                            meals: pkg.mealsFormatted || "",
                                            name: pkg.dayTourName,
                                            thumbnailImageUrl: pkg.thumbnailImageUrl || "",
                                            url: virtuosoSharedHeader.cobrandLink(`/packages/${pkg.dayTourMasterEntityID}/${virtuosoSharedHeader.slugify(pkg.dayTourName)}`),
                                            virtuosoHotel: ""
                                        });
                                    });
                                }
                            });
                            thisTour.addOnDayTours = dayTours;
                        }


                        // Promotions -- Virtuoso Exclusive Promotions added first
                        if (resultTour.promotions && resultTour.promotions.length) {
                            const visiblePromotions: Promotion[] = [];
                            resultTour.promotions.forEach((promo) => {
                                if (!promo.isAdvisorIncentive && !promo.incentiveTypeCode) {
                                    visiblePromotions.push({
                                        description: promo.description || "",
                                        endDateMS: (promo.travelEndDate) ? new Date(promo.travelEndDate).getTime() : 0,
                                        formattedTravelDates: promo.formattedTravelDates || "",
                                        isExclusive: false,
                                        name: promo.promotionName || "",
                                        promotionId: promo.masterEntityId,
                                        startDateMS: (promo.travelStartDate) ? new Date(promo.travelStartDate).getTime() : 0,
                                        url: (promo.masterEntityId) ? virtuosoSharedHeader.cobrandLink(`/promotions/${promo.masterEntityId}`) : ""
                                    });
                                }
                            });

                            thisTour.promotions = visiblePromotions;
                            this.topPromoName = (thisTour.promotions.length) ? thisTour.promotions[0].name : "";
                        }
                        
                        this.tour = thisTour;

                        this.topSplashData = {
                            companyName: thisTour.companyName,
                            featuredImageCaption: thisTour.featuredImageCaption,
                            featuredImageUrl: thisTour.featuredImageUrl,
                            featuredVideoUrl: thisTour.featuredVideoUrl,
                            productName: thisTour.tourName,
                            productType: ProductType.TOURS,
                            ...(thisTour.promotions[0] && { promotion: thisTour.promotions[0] }),
                            wanderlistId: this.heartableUrl,
                            wanderlistName: this.wanderlistTourName
                        } as ProductTopSplash;



                        // Recommended Advisors -- country of second day for multi-day tours, first day otherwise
                        let recommendedAdvisorCountry = "";
                        if (thisTour.itinerary.length) {
                            if (this.isDayTour) {
                                recommendedAdvisorCountry = thisTour.itinerary[0].country;
                            } else {
                                recommendedAdvisorCountry = (thisTour.itinerary.length > 1) ? thisTour.itinerary[1].country : thisTour.itinerary[0].country;
                            }
                        }

                        this.recommendedAdvisorQuery = {
                            Id: thisTour.id,
                            InterestType: "",
                            ProductLocationCountry: recommendedAdvisorCountry,
                            ProductPois: "",
                            ProductTypeName: "Tour"
                        } as RecommendedAdvisorQuery;

                        this.isReady = true;
                        // Onload scroll to tab support 
                        this.showThenJumpToTab();

                        nextTick(() => {
                            if (thisTour.tourDescription) {
                                applyVerticalTruncation($(".truncate-vertical", ".product-detail-overview"));
                            }

                            enableHearts(this.$refs["product-detail"]);
                        });

                    } else {
                        this.redirectOnError();
                    }
                }, () => this.redirectOnError());
            },

            redirectOnError(): void {
                toastError("Error retrieving data");
                setTimeout(function () {
                    location.href = virtuosoSharedHeader.cobrandLink(`/travel/luxury-tours`);
                }, 3000);
            },
            
            scrollToTabResponsive(tabName = ""): void {
                if (isMobileScreenWidth()) {
                    return (tabName) ? document.getElementById(`tc-${tabName}`)?.scrollIntoView()
                        : document.querySelector(".tab-content .-active")?.scrollIntoView({ block: "start" });
                }
                this.$refs.tabNavContainer?.scrollIntoView();
            },

            setRecommendedAdvisorIds: function (advisor1MEID?: number, advisor2MEID?: number): void {

                const getVirtuosoTourSearchIndexValue = sessionStorage.getItem(`VirtuosoTourSearchIndex_${this.productId}`);
                const tour: ProductDetailsTour = this.tour;

                if (getVirtuosoTourSearchIndexValue) {

                    sessionStorage.removeItem(`VirtuosoTourSearchIndex_${this.productId}`);
                }

                trackEvent("view_item", {
                    ...((getVirtuosoTourSearchIndexValue) && { index: parseInt(getVirtuosoTourSearchIndexValue, 10) }), // should add index with value if session key existed
                    item_id: `${this.productId}`,
                    item_name: tour.tourNameWithoutLength,
                    coupon: (tour.promotions.length >= 1) ? "Promotion Available" : "",
                    item_category: "Tour",
                    item_category2: (tour.activityLevel) ? `${tour.activityLevel}` : "",
                    item_category3: tour.tourLength,
                    item_category4: (advisor1MEID) ? `${advisor1MEID}` : "",
                    item_category5: (advisor2MEID) ? `${advisor2MEID}` : "",
                    item_variant: `${tour.galleryImages.length} photos`
                });
            },

            showTab(tabName: string, preventJump = false, fromTopLink = false): void {
                $(".tab-nav-container").children().removeClass("-active");
                $(".tab-content").children().removeClass("-active");
                $(`#tab-${tabName}, #tc-${tabName}`).addClass("-active");
                if (!preventJump) {
                    this.scrollToTabResponsive(tabName);
                }

                if (fromTopLink) {

                    trackEvent("view_promotion", {
                        item_id: `${this.tour.promotions[0].promotionId}`,
                        item_name: this.topPromoName,
                        item_category: "Promotion",
                        item_variant: "Tour",
                        item_category2: "Promo Visibility: All",
                        affiliation: `${this.productId}`
                    });
                }
            },

            showThenJumpToTab(): void {
                // Which bottom tab should be selected by default
                const defaultTabName = 
                    (this.tour.itinerary.length > 1) ? "itinerary" :
                    (this.tour.virtuosoBenefits) ? "benefits" : 
                    (this.tour.promotions.length) ? "promotions" : 
                    (this.tour.addOnDayTours.length) ? "add-ons" : "";

                const qsTabLabel = qsParams["tab"];

                this.showTabBlock = (defaultTabName.length > 0);
                
                nextTick(() => { // Tab Show then Scroll
                    // Show
                    if (defaultTabName) { // first tab
                        this.showTab(defaultTabName, true);
                    }
                    if (qsTabLabel && document.getElementById(`tc-${qsTabLabel}`)) { // check qsParam tab exists
                        this.showTab(qsTabLabel, true);
                    }
                    // Show Promo Manual Tab
                    if (qsParams.promotions === "1") { // If the manually added ?promotions=1 query string variable is present, scroll to the promotions section on load
                        const qsPromoTabName = (this.tour.promotions.length) ? "promotions" : defaultTabName;
                        this.showTab(qsPromoTabName, true);                           
                    }
                    // Default Scroll
                    if (("tab" in qsParams) || ("promotions" in qsParams)) {
                        this.scrollToTabResponsive();
                    }
                });

            },

            toggleAddOn(id: string): void {
                const $btn = $(`#add-on-toggle-${id}`);
                const $container = $(`#add-on-container-${id}`);

                $btn.toggleClass("icon-plus-circle-ut icon-minus-circle-ut");
                $container.slideToggle(500);
            },

            toggleDesc(day: number): void {
                const $dayBtn = $(`#day-toggle-${day}`);
                const $dayDesc = $(`#day-description-${day}`);

                if ($("i", $dayBtn).hasClass("icon-angle-down")) {
                    $dayBtn.html(`Fewer details <i class="icon-angle-up"></i>`);
                } else {
                    $dayBtn.html(`More details <i class="icon-angle-down"></i>`);
                }

                $dayDesc.slideToggle(500);
            },

            trackPromotionDetailClick(): boolean {
                if (this.isAdvisorOrVStaff) {
                    setB2BDesktopCookie();
                }

                return true;
            }
        }

    });
</script>
